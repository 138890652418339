import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj30 = () => {
  return (
    <Layout>
      <SEO title="Spiral Traversing" description="Awesome python project" />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-30">
          <div className="py-1-heading">
            <h1>Spiral Traversing</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Spiral%20Traversing"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>
        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              We are going to traverse a matrix in spiral form
              <br />
              <br />
              All you need to take care of orders and positions in matrix
              <br />
              <br />
              I did it in two flavours :-
              <br />
              => Traversing a matrix using loops
              <br />
              => A real spiral animation using Turtle module
              <br />
              (After all, to create a feeling is impotant !)
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Turtle</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj30
